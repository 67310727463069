
import moment from "moment";
import ContactName from "./contact-name";
import EmailDropdown from "./email-dropdown";
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";

export default {
    name: "SelectContacts",
    components: {EmailDropdown, ContactName},
    mixins:[
        emailPopoverActionsMixin,
    ],
    props: {
        value: {
            type: Array,
            required: true,
        },
        hideWithoutEmail: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selectedRows: [],
            preSelectedRowIds: [],
            preSelectedRows: [],
            ajaxRows: [],
            ajaxAll: 0,
            searchQuery: "",
            mode: "none",
            pageChanged: false,
            modeUpdated: moment().valueOf()
        }
    },

    computed: {
        realSelectedContacts() {
            switch (this.mode) {
                case "none":
                    return 0;
                // case "filtered":
                //     return this.ajaxAll;
                case "page":
                    return this.ajaxRows.length;
                case "selected":
                    return this.preSelectedRowIds.length;
                default:
                    return 0
            }
        },
        ajaxConfig() {
            return {
                columns: [
                    {
                        headline: "Name",
                    },
                    {
                        hidden: true
                    },
                    {
                        hidden: true,
                    },
                    {
                        hidden: true,
                    },
                    {
                        headline: "Email",
                    },
                    {
                        hidden: true,
                    },
                    {
                        hidden: true
                    },
                    {
                        hidden: true,
                    },
                    {
                        hidden: true,
                    },
                    {
                        hidden: true,
                    },
                    {
                        hidden: true,
                    },
                ],
                pagination: 10,
                search: true,
                select: true,
                selectPosition: "pre",
                selectAll: true,
                prettySelect: true,
                pageSortSelect: true,
                emptyPlaceholder: "No Contacts...",
                ajaxUrl: "/api/contacts/list",
                disallowAllOption: true,
            }
        },
        realSelectedRows() {
            let ids = [];

            switch (this.mode) {
                // case "filtered":
                //
                //     ids = {
                //         all: this.ajaxAll,
                //         filters: this.filters,
                //         query: this.searchQuery,
                //     };
                //     break;
                case "selected":
                    ids = this.preSelectedRowIds;
                    break;
                case "page":
                    ids = this.ajaxRows.map(function (row) {
                        return row.contact_id;
                    });
                    break;
                case "none":
                default:
                    // do nothing
                    break;
            }

            return ids;
        },
        filters() {
            return {
                'mustHaveEmail': this.hideWithoutEmail,
            };
        },
    },

    watch: {
        filters() {
            this.pageChanged = true;
        },
        selectedRows(rows) {
            if (rows.length) {
                this.mode = "selected"
            }
            if (!this.pageChanged) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].contact_id)) {
                        this.preSelectedRowIds.push(rows[i].contact_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].contact_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.contact_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        },
        mode(val, old) {
            if (val !== "selected" && old === "selected" && (this.selectedRows.length || this.preSelectedRowIds.length)) {
                this.selectedRows = [];
                this.preSelectedRowIds = [];
                this.modeUpdated = moment().valueOf()
            }

        },
        realSelectedRows: {
            handler(val) {
                this.$emit("input", val)
            },
            deep: true
        }
    },
    methods: {
        onPageChange() {
            this.pageChanged = true;
        },
        onQueryUpdate(query) {
            this.searchQuery = query;
            this.pageChanged = true;
        },
        onUpdate(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowIds.map(id => {
                    return {
                        key: "contact_id",
                        value: id
                    }
                });
            }
            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },

    }
}
