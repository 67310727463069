
import SaveButton from './save-button';
import LoadingSpinner from './loading-spinner.vue';
import DownloadSelectColumnsForm from './company/download-select-columns-form';

export default {
    name: 'DownloadSelectColumnsModal',
    components: { LoadingSpinner, SaveButton, DownloadSelectColumnsForm },
    props: {
        id: {
            type: String,
            default: 'DownloadSelectColumnsModal',
        },
        title: {
            type: String,
            default: 'Download',
        },
        excelExportColumnsPath: {
            type: String,
            required: true,
        },
        exportPath: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        async download() {
            this.isLoading = true;
            await this.$refs.columnsForm.download();
            this.isLoading = false;
        },
    },
};
